
<script>
  // Extensions
  import View from '@/views/View'

  // Mixins
  import LoadSections from '@/mixins/load-sections'
  import { mapState } from 'vuex'

  export default {
    name: 'Home',

    metaInfo () { return { title: `${this.vibeUser?.firstName || 'Home'}` } },

    extends: View,

    mixins: [
      LoadSections([
        'signature',
      ]),
    ],

    props: {
      id: {
        type: String,
        default: 'home',
      },
    },

    computed: {
      ...mapState('app', ['vibeUser']),
    },
  }
</script>
